<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>赛务管理</el-breadcrumb-item>
            <el-breadcrumb-item>个人赛赛务工具</el-breadcrumb-item>
            <el-breadcrumb-item
                :to="{
                    path: '/individual_racing_tool/individual_gametime_manage',
                }"
                >比赛时间管理</el-breadcrumb-item
            >
            <el-breadcrumb-item>消息推送</el-breadcrumb-item>
        </el-breadcrumb>

        <el-page-header @back="goBack"></el-page-header>
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">消息发送管理</span>
                </el-col>
            </el-row>
            <el-row>
                <el-form
                    label-width="80px"
                    ref="queryPushFormRef"
                    :rules="queryPushFormRules"
                    :model="pushMessageModel"
                >
                    <el-row :gutter="10">
                        <el-col :span="6">
                            <el-form-item label="消息模板" prop="flag">
                                <el-select
                                    placeholder="请选择"
                                    style="width: 100%"
                                    v-model="pushMessageModel.flag"
                                >
                                    <el-option
                                        v-for="item in dict_pushMsg"
                                        :key="item.dictValue"
                                        :label="item.dictLabel"
                                        :value="item.dictValue"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <el-row :gutter="20" type="flex" justify="end">
                    <el-col :span="2">
                        <el-button type="primary" @click="pushAllMessaging()"
                            >全量发送</el-button
                        >
                    </el-col>
                    <el-col :span="2">
                        <el-button
                            type="primary"
                            @click="showSendMessageClicked()"
                            >指定发送</el-button
                        >
                    </el-col>
                </el-row>
            </el-row>
        </el-card>
        <!-- <el-card> -->
        <el-tabs
            v-model="activeName"
            type="card"
            @tab-click="tabHandleClick"
            style="margin-top: 15px"
        >
            <el-tab-pane label="发送成功" name="first">
                <!-- 赛区列表 -->
                <el-card>
                    <el-table
                        header-cell-class-name="tableHeaderStyle"
                        changes
                        border
                        :data="pushSuccessList"
                    >
                        <el-table-column
                            label="序号"
                            type="index"
                        ></el-table-column>
                        <el-table-column
                            label="手机号"
                            prop="phone"
                        ></el-table-column>
                        <el-table-column
                            label="用户名"
                            prop="userName"
                        ></el-table-column>
                        <el-table-column
                            label="推送信息"
                            prop="errmsg"
                            :show-overflow-tooltip="true"
                        ></el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pushMessageModel.pageNum"
                        :page-sizes="[10, 20, 40, 80]"
                        :page-size="pushMessageModel.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                    >
                    </el-pagination>
                </el-card>
            </el-tab-pane>
            <el-tab-pane label="发送失败" name="second">
                <el-card>
                    <el-row :gutter="20">
                        <el-col>
                            <el-button
                                type="primary"
                                plain
                                icon="el-icon-refresh-right"
                                size="mini"
                                @click="resetSendMessage"
                                >重新发送</el-button
                            >
                        </el-col>
                    </el-row>
                    <el-table
                        header-cell-class-name="tableHeaderStyle"
                        changes
                        border
                        :data="pushFailList"
                    >
                        <el-table-column
                            label="序号"
                            type="index"
                        ></el-table-column>
                        <el-table-column
                            label="手机号"
                            prop="phone"
                        ></el-table-column>
                        <el-table-column
                            label="用户名"
                            prop="userName"
                        ></el-table-column>
                        <el-table-column
                            label="推送信息"
                            prop="errmsg"
                            :show-overflow-tooltip="true"
                        ></el-table-column>
                        <el-table-column label="操作" width="210px">
                            <template slot-scope="scope">
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="pushMessaging(scope.row)"
                                    >消息推送</el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pushMessageModel.pageNum"
                        :page-sizes="[10, 20, 40, 80]"
                        :page-size="pushMessageModel.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                    >
                    </el-pagination>
                </el-card>
            </el-tab-pane>
            <el-tab-pane label="未发送" name="third">
                <el-card>
                    <el-row :gutter="20">
                        <el-col>
                            <el-button
                                type="primary"
                                plain
                                icon="el-icon-s-promotion"
                                size="mini"
                                @click="beginSendMessage"
                                >开始推送</el-button
                            >
                        </el-col>
                    </el-row>
                    <el-table
                        header-cell-class-name="tableHeaderStyle"
                        changes
                        border
                        :data="unPushList"
                    >
                        <el-table-column
                            label="序号"
                            type="index"
                        ></el-table-column>
                        <el-table-column
                            label="手机号"
                            prop="phone"
                        ></el-table-column>
                        <el-table-column
                            label="中文名"
                            prop="userName"
                        ></el-table-column>
                        <el-table-column
                            label="英文名"
                            prop="enName"
                        ></el-table-column>
                        <el-table-column label="操作" width="210px">
                            <template slot-scope="scope">
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="pushMessaging(scope.row)"
                                    >消息推送</el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pushMessageModel.pageNum"
                        :page-sizes="[10, 20, 40, 80]"
                        :page-size="pushMessageModel.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                    >
                    </el-pagination>
                </el-card>
            </el-tab-pane>
        </el-tabs>
        <el-dialog
            title="发送消息"
            :visible.sync="showSendMessage"
            width="50%"
            @close="showSendMessageClose"
        >
            <el-form
                :model="showSendMessageForm"
                ref="showSendMessageFormRef"
                :rules="showSendMessageFormRules"
                label-width="140px"
            >
                <el-form-item label="小程序账号" prop="phoneStr">
                    <el-input
                        type="textarea"
                        placeholder="批量发送账号请以“英文逗号”分隔"
                        v-model="showSendMessageForm.phoneStr"
                    >
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="sendMessageClicked"
                    >发 送</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getUserByPushServerNumber, getUserByNotPushServerNumber, pushServerNumberMatch, pushByFalsePushServerNumber, pushByNotPushServerNumber, pushServerNumberMatchByPhoneList, pushServerNumberMatchByUserIdList } from '@/http/api'
export default {
  data () {
    return {
      dict_pushMsg: this.$userInfo.getDataList(this, 'push_category').then(value => { this.dict_pushMsg = value }),
      showSendMessage: false,
      showSendMessageForm: {
        districtRaceTimeId: this.$route.query.rowDataId,
        phoneStr: '',
        phones: []
      },
      queryPushFormRules: {
        flag: [
          { required: true, message: '请选择推送模板', trigger: 'change' }
        ]
      },
      showSendMessageFormRules: {
        phoneStr: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ]
      },
      pushMessageModel: {
        flag: 'districtraceMatchTime',
        id: this.$route.query.rowDataId,
        pageNum: 1,
        pageSize: 10,
        state: null
      },
      total: 0,
      activeName: 'first',
      pushSuccessList: [], // 发送成功列表
      pushFailList: [], // 发送失败列表
      unPushList: [] // 未发送列表
    }
  },
  created () {
    this.getUserByPushServerNumber(true)
  },
  methods: {
    // 获取已推送的消息
    getUserByPushServerNumber (state) {
      this.pushMessageModel.state = state
      getUserByPushServerNumber(this.pushMessageModel).then((res) => {
        if (state === true) {
          this.pushSuccessList = res.data.list
        } else {
          this.pushFailList = res.data.list
        }
        this.total = res.data.total
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 获取未推送消息
    getUserByNotPushServerNumber () {
      getUserByNotPushServerNumber(this.pushMessageModel).then((res) => {
        this.unPushList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    goBack () {
      this.$router.back()
    },
    // tab卡切换点击事件
    tabHandleClick (tag) {
      console.log(this.activeName)
      this.pushMessageModel.pageNum = 1
      if (this.activeName === 'first') {
        this.getUserByPushServerNumber(true)
      } else if (this.activeName === 'second') {
        this.getUserByPushServerNumber(false)
      } else {
        this.getUserByNotPushServerNumber()
      }
    },
    handleSizeChange (newSize) {
      this.pushMessageModel.pageSize = newSize
      if (this.activeName === 'first') {
        this.getUserByPushServerNumber(true)
      } else if (this.activeName === 'second') {
        this.getUserByPushServerNumber(false)
      } else {
        this.getUserByNotPushServerNumber()
      }
    },
    handleCurrentChange (newCurPage) {
      this.pushMessageModel.pageNum = newCurPage
      if (this.activeName === 'first') {
        this.getUserByPushServerNumber(true)
      } else if (this.activeName === 'second') {
        this.getUserByPushServerNumber(false)
      } else {
        this.getUserByNotPushServerNumber()
      }
    },
    // 全量发送
    pushAllMessaging () {
      this.$refs.queryPushFormRef.validate(async valid => {
        if (!valid) return
        this.$confirm('是否推送全部用户消息?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          pushServerNumberMatch(this.pushMessageModel).then((res) => {
            this.pushMessageModel.pageNum = 1
            this.$message.success('已推送')
          }).catch((err) => {
            console.log('返回的err', err)
          })
        }).catch(() => {
        })
      })
    },
    // 重新发送失败的消息
    resetSendMessage () {
      this.$refs.queryPushFormRef.validate(async valid => {
        if (!valid) return
        this.$confirm('是否确认推送该模板消息?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          pushByFalsePushServerNumber(this.pushMessageModel).then((res) => {
            this.$message.success('已推送')
            this.pushMessageModel.pageNum = 1
            this.getUserByPushServerNumber(false)
          }).catch((err) => {
            console.log('返回的err', err)
          })
        }).catch(() => {
        })
      })
    },
    // 未发送的开始发送
    beginSendMessage () {
      this.$refs.queryPushFormRef.validate(async valid => {
        if (!valid) return
        this.$confirm('是否确认推送该模板消息?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          pushByNotPushServerNumber(this.pushMessageModel).then((res) => {
            this.$message.success('已推送')
            this.pushMessageModel.pageNum = 1
            this.getUserByNotPushServerNumber()
          }).catch((err) => {
            console.log('返回的err', err)
          })
        }).catch(() => {
        })
      })
    },
    // 单独推送消息
    pushMessaging (rowData) {
      this.$refs.queryPushFormRef.validate(async valid => {
        if (!valid) return
        this.$confirm('是否确认推送该模板消息?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          console.log('rowData', rowData)
          pushServerNumberMatchByUserIdList({ districtRaceTimeId: this.pushMessageModel.id, ids: [rowData.userId ? rowData.userId : rowData.id], flag: this.pushMessageModel.flag }).then((res) => {
            this.$message.success('已推送')
            this.pushMessageModel.pageNum = 1
            this.getUserByNotPushServerNumber()
          }).catch((err) => {
            console.log('返回的err', err)
          })
        }).catch(() => {
        })
      })
    },
    showSendMessageClicked () {
      this.showSendMessage = true
    },
    showSendMessageClose () {
      this.$refs.showSendMessageFormRef.resetFields()
    },
    sendMessageClicked () {
      this.$refs.showSendMessageFormRef.validate(async valid => {
        if (!valid) return
        this.$confirm('是否确认推送该模板消息?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.showSendMessageForm.phones = this.showSendMessageForm.phoneStr.split(',')
          this.showSendMessageForm.flag = this.pushMessageModel.flag
          pushServerNumberMatchByPhoneList(this.showSendMessageForm).then((res) => {
            this.showSendMessage = false
            this.$message.success('已推送')
            this.pushMessageModel.pageNum = 1
          }).catch((err) => {
            console.log('返回的err', err)
          })
        }).catch(() => {
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.div_algin_right {
    text-align: right;
}
::v-deep .el-tabs--card > .el-tabs__header {
    background: #fff;
    margin-bottom: 0;
    border-bottom: none;
    border-top-left-radius: 4px;
}
</style>
